@tailwind base;
@tailwind components;
@tailwind utilities;

h1, h2, h3, h4, h5, h6 {
  @apply font-display text-black;
}

h1, h2 {
  @apply text-2xl sm:text-2xl md:text-3xl font-semibold;
}

h2 {
  @apply text-2xl sm:text-3xl font-medium;
}

body {
  @apply font-body text-black;
}

/* Apply any overrides to links within markdown */
.markdown-text a {
  @apply underline
}

.markdown-text a:hover {
  @apply text-gray-700
}
.swiper-button-prev,
.swiper-button-next {
  color: #000;
}